<script lang="ts" setup>
import { ParagraphMegaTeaser, MediaImageFragment, ParagraphMegaTeaserItem } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphMegaTeaser
}>()
const image = {
  small: props.entity.fieldMobileImage?.entity as MediaImageFragment | undefined,
  large: props.entity.fieldImage?.entity as MediaImageFragment | undefined,
}

const title = computed(() => props.entity.fieldTitle || '')
const description = computed(() => props.entity.fieldDescription?.processed || '')
const cta = computed(() => {
  return {
    label: props.entity.fieldLink?.title || '',
    url: props.entity.fieldLink?.url?.path || '',
    target: props.entity.fieldLink?.options?.attributes?.target || '_self',
  }
})
const accordionItems = computed(() => {
  return props.entity.fieldItems?.map((item) => {
    const teaser = item?.entity as ParagraphMegaTeaserItem
    return {
      title: teaser.fieldTitle || '',
      content: teaser.fieldDescription?.processed,
      link: {
        label: teaser.fieldLink?.title,
        url: teaser.fieldLink?.url?.path,
        target: teaser.fieldLink?.options?.attributes?.target || '_self',
      },
    }
  })
})
</script>

<template>
  <Section large>
    <Picture v-if="image.small && image.large" class="image" :small="image.small" :large="image.large" type="hero" />
    <div class="content">
      <h2 class="title text-xlarge">{{ title }}</h2>
      <div v-if="description" class="description" v-html="description" />
      <Button v-if="cta.url" secondary :to="cta.url" :target="cta.target">{{ cta.label }}</Button>
    </div>
    <Accordion :active-index="0">
      <AccordionItem v-for="(item, index) in accordionItems" :key="index" :index="index" :title="item.title">
        <TextSpecial v-if="item.content" class="text-small" :text="item.content" />
        <NuxtLink
          v-if="item.link?.url"
          class="text-small link-default"
          :to="item.link?.url"
          :target="item.link.target"
          >{{ item.link?.label }}</NuxtLink
        >
      </AccordionItem>
    </Accordion>
  </Section>
</template>
<style lang="scss" scoped>
.section {
  @include fluid(--accordion-item-height, 70px, 90px);
  grid-template-rows: 1fr auto;

  @include breakpoint(tl) {
    grid-template-rows: 1fr var(--accordion-item-height);
  }
}

.image {
  @include fluid(min-height, 420px, 800px);
  grid-column: 1 / -1;
  grid-row: 1;

  position: relative;

  --margin: calc(50% - min(50vw, calc(var(--layout-max-width) / 2)));
  margin-left: var(--margin);
  margin-right: var(--margin);

  z-index: -1;
  @include breakpoint(tl) {
    grid-row: 1 / 2;
  }

  &:after {
    content: '';
    @include fill;
    background: linear-gradient(104.04deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }

  :deep(img) {
    @include fill;
  }
}

.content {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  align-self: end;

  @include fluid(padding-block, 30px, 80px);

  @include breakpoint(tl) {
    grid-column: 1 / 7;
    align-self: start;
  }
  @include breakpoint(ds) {
    grid-column: 1 / 5;
    align-self: start;
  }

  * {
    color: var(--c-white);
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .title {
    hyphens: none;
    overflow-wrap: initial;
  }
}

.accordion {
  --margin: calc(50% - min(50vw, calc(var(--layout-max-width) / 2)));
  margin-inline: var(--margin);

  @include breakpoint(tl) {
    grid-column: 7 / -1;
    grid-row: 1 / 3;

    align-self: end;
    margin-inline: 0;
  }

  @include breakpoint(ds) {
    grid-column: 8 / -1;
  }
}

.accordion-item {
  background-color: var(--c-background-medium);
  border: none;
  margin-bottom: 0;
  --padding: calc((100vw - min(100% - 2 * var(--container-padding), var(--container-max-width))) / 2);

  &.is-active {
    position: relative;
    z-index: 11;
    background-color: var(--c-white);
    box-shadow: 0px 20px 50px 0px rgba(10, 21, 92, 0.15);
  }

  :deep(.accordion-head) {
    padding-left: var(--padding);
    padding-right: calc(var(--padding) - 20px);
    @include breakpoint(tl) {
      padding-left: 40px;
      padding-right: 20px;
    }
  }
  :deep(.accordion-content) {
    padding-inline: var(--padding);
    @include breakpoint(tl) {
      padding-inline: 40px;
    }
  }

  :deep(.accordion-head .button .background) {
    display: none;
  }
}
</style>
